// import React from 'react'
// import { Nav, Navbar, Container, Dropdown, DropdownButton, Carousel } from 'react-bootstrap';
// // import Phone from '../cmp/phone'

// import {Link} from 'react-router-dom'
// const Headers = () => {
//     const customStyle = {
//         position: 'sticky',
//         top: 0,
//         zIndex: 1000,
//         boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
//         background: '#182025'
//       };


//   return (
//     <>
    
//             <div className='container-fluid py-3' style={customStyle}>
//                 <div className='container mob-res'>

//                     <div className='row'>
//                         <div className='col-md-12 px-5 nav-mob'>
//                             <div>

//                                 <Navbar expand="lg"   className="bg-body-tertiary py-0">
//                                     <Container fluid className='' style={{ background: '#182025' }}>
//                                         <Navbar.Brand href="/" className='logo-mob'><img src='com-logo2.jpg' className='me-3' style={{ width: '100px' }} /><span className='fw-bold fs-4' style={{color:'#E62E5E'}}></span></Navbar.Brand>
//                                         <Navbar.Toggle aria-controls="navbarScroll" />
//                                         <Navbar.Collapse id="navbarScroll">
//                                             <div className=' d-flex justify-content-start' id='nav-id' style={{ width: '100%' }}>
//                                                 <Nav
//                                                     className="my-2 my-lg-0"
//                                                     style={{ maxHeight: '300px' }}
//                                                     navbarScroll
//                                                 >

//                                                     <Nav.Link href='/' className='nav-link fw-bold text-white fs-5' style={{ padding: '8px 20px' }}>Home</Nav.Link>
//                                                     <Link to="/about-us" className='nav-link fw-bold text-white fs-5' style={{ padding: '8px 20px' }}>About Us</Link>

                                                  
                                                   
//                                                     <Link to="/services" className='nav-link fw-bold text-white fs-5' style={{ padding: '8px 20px' }}>Services</Link>
//                                                     <Link to="/teams" className='nav-link fw-bold text-white fs-5' style={{ padding: '8px 20px' }}>Teams</Link>
//                                                     <Link to="/projects" className='nav-link fw-bold text-white fs-5' style={{ padding: '8px 20px' }}>Projects</Link>
//                                                     {/* <Link to="/services" className='nav-link fw-bold text-white fs-5' style={{ padding: '8px 20px' }}>Blogs</Link> */}
//                                                     <Link to="/contactus" className='nav-link me-2 fw-bold text-white fs-5' style={{ padding: '8px 20px' }}>Contact Us</Link>
//                                                     {/* <input type='search' placeholder='Search.....' style={{ borderRadius: '30px', padding: '0px 10px', outline: 'none' }} /> */}

//                                                 </Nav>
//                                             </div>
//                                             {/* <Form className="d-flex">
//                                             <Form.Control
//                                                 type="search"
//                                                 placeholder="Search"
//                                                 className="me-2"
//                                                 aria-label="Search"
//                                             />
//                                             <Button variant="outline-success">Search</Button>
//                                         </Form> */}
//                                         </Navbar.Collapse>
//                                     </Container>
//                                 </Navbar>
//                             </div>
//                         </div>
//                     </div>
                   
//                 </div>
//             </div>


//     </>
//   )
// }

// export default Headers



import React, { useState } from 'react';
import { Navbar, Nav, Container,Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Phone from './phone'
import "../cmp/style1.css";

const Headers = () => {
  const [sideNavWidth, setSideNavWidth] = useState(0);

  const toggleNav = () => {
    setSideNavWidth(sideNavWidth === 0 ? 250 : 0);
  };

  return (
    <div style={{ marginLeft: `${sideNavWidth}px`, transition: 'margin 0.5s' }}>
      {/* Main Navbar */}
      <Navbar expand="lg" className="bg-body-tertiary py-0">
        <Container fluid className='' style={{ background: '#182025' }}>
          <Navbar.Brand href="/" className='logo-mob px-4 py-3'>
            <img src='com-logo2.jpg' className='me-3' style={{ width: '80px' }} />
            <span className='fw-bold fs-4' style={{ color: '#E62E5E' }}></span>
          </Navbar.Brand>
          <div className='d-flex'>
          <Button aria-controls="navbarScroll" id='hide' onClick={toggleNav} className='bg-secondary border-0'><i class="fa fa-bars fs-1"></i></Button>
          <Navbar.Collapse id="navbarScroll">
            <Nav className="my-2 my-lg-0" style={{ maxHeight: '300px' }} navbarScroll>
              <Nav.Link href='/' className='nav-link fw-bold text-white fs-5' style={{ padding: '8px 20px' }}>Home</Nav.Link>
              <Link to="/about-us" className='nav-link fw-bold text-white fs-5' style={{ padding: '8px 20px' }}>About Us</Link>
              <Link to="/services" className='nav-link fw-bold text-white fs-5' style={{ padding: '8px 20px' }}>Services</Link>
              <Link to="/teams" className='nav-link fw-bold text-white fs-5' style={{ padding: '8px 20px' }}>Teams</Link>
              <Link to="/projects" className='nav-link fw-bold text-white fs-5' style={{ padding: '8px 20px' }}>Projects</Link>
              <Link to="/contactus" className='nav-link me-2 fw-bold text-white fs-5' style={{ padding: '8px 20px' }}>Contact Us</Link>
            </Nav>
          </Navbar.Collapse>
          </div>
        </Container>
      </Navbar>

      {/* SideNavbar component */}
      <div id="mySidenav" style={{ width: `${sideNavWidth}px`, transition: 'width 0.5s', position: 'fixed',top: '0', left: '0', backgroundColor: '#111', height: '100%', overflowX: 'hidden', zIndex:'99999' }}>
        {/* Your side navigation content goes here */}
        <a href="#" style={{ textDecoration: 'none', color: '#818181', fontSize: '40px', display: 'block', transition: '0.3s', textAlign:'end'}} onClick={toggleNav}>&times;</a>
        <Link to="/" className='text-center' style={{ textDecoration: 'none', color: '#818181', fontSize: '25px', padding: '8px 8px 16px 8px', display: 'block', transition: '0.3s', }}>Home</Link>
        <Link to="/about-us" className='text-center text-white' style={{ textDecoration: 'none', color: '#818181', fontSize: '25px', padding: '8px 8px 16px 8px', display: 'block', transition: '0.3s', }}>About Us</Link>
        <Link to="/services" className='text-center' style={{ textDecoration: 'none', color: '#818181', fontSize: '25px', padding: '8px 8px 16px 8px', display: 'block', transition: '0.3s', }}>Services</Link>
        <Link to="/teams"  className='text-center'style={{ textDecoration: 'none', color: '#818181', fontSize: '25px', padding: '8px 8px 16px 8px', display: 'block', transition: '0.3s', }}>Teams</Link>
        <Link to="/projects" className='text-center' style={{ textDecoration: 'none', color: '#818181', fontSize: '25px', padding: '8px 8px 16px 8px', display: 'block', transition: '0.3s', }}>Projects</Link>
        <Link to="/contactus" className='text-center' style={{ textDecoration: 'none', color: '#818181', fontSize: '25px', padding: '8px 8px 16px 8px', display: 'block', transition: '0.3s', }}>Contact Us</Link>
      </div>

      {/* Button to toggle the side navigation */}
     
    </div>
  );
};

export default Headers;
