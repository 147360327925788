import React from 'react';
import '../cmp/style.css';

const phoneNumber = '919900118650';
const whatsappWebLink = `https://web.whatsapp.com/send?phone=${phoneNumber}`;
const whatsappAppLink = `https://api.whatsapp.com/send?phone=${phoneNumber}`;

const WhatsAppIcon = () => {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const openWhatsApp = () => {
    if (isMobile) {
      window.open(whatsappAppLink, '_blank');
    } else {
      window.open(whatsappWebLink, '_blank');
    }
  };

  return (
    <button className="whatsapp-button" onClick={openWhatsApp}>
      <img src="whatapp.png" alt="WhatsApp Icon" />
    </button>
  );
};

export default WhatsAppIcon;
