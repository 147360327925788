import React from 'react'

import { Link, useHistory } from 'react-router-dom';
// import { Link } from 'react-router-dom'
const Footer = () => {
    return (

        <>
            <div className='container-fluid py-3 pt-5' style={{ background: '#141D30', width: '100%' }}>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-4 text-center'>
                            <img src='com-logo2.jpg' className='mb-4 me-3' style={{ width: '80px', }} /><spna className='text-white fs-5 fw-bold'>Vasundara Contruction
                            </spna>
                            <div>
                                <p className='text-white mb-5 fs-5' style={{ textAlign: 'justify' }}>
                                    At Vasundhara Constructions, we are dedicated to delivering high-quality construction solutions that meet the unique requirements of educational institutions, healthcare facilities, government buildings, and more.
                                </p>
                                <h5 className='text-start text-white mb-5'>Follows Us :


                                    <span className='mx-2'
                                        style={{ width: '70px', height: '70px', backgroundColor: '#3B5998', padding: '10px 17px', borderRadius: '50%' }}

                                    >
                                        <a className='text-white' target='_blank' href="https://www.facebook.com/profile.php?id=100067143518218">
                                            <i class="fa fa-facebook"></i>
                                        </a>
                                    </span>


                                    <span className='mx-2'
                                        style={{ width: '70px', height: '70px', backgroundColor: '#3B77B5', padding: '10px 15px', borderRadius: '50%' }}

                                    >
                                        <a class="text-white" target='_blank' href="https://www.linkedin.com/in/rajendra-prasad-ginnam-6a390788/">
                                            <i className='fa fa-linkedin'></i>
                                        </a>
                                    </span>


                                    <span className='mx-2'
                                        style={{ width: '70px', height: '70px', backgroundColor: '#52A1F3', padding: '10px 14px', borderRadius: '50%' }}

                                    >
                                        <a class="text-white" target='_blank' href="https://twitter.com/rajendr86502997">
                                            <i className='fa fa-twitter'></i>
                                        </a>
                                    </span>
                                </h5>

                            </div>
                        </div>
                        <div className='col-md-4 text-center d-flex align-items-center flex-column'>
                            <h4 className='text-white mb-4 text-start ms-5'><span style={{ borderBottom: '3px solid #E62E5E', paddingBottom: '15px' }}>Pages</span></h4>
                            <div>
                                <ul style={{ listStyle: 'none' }}>
                                    <li className='py-2'>
                                        <Link to='/' className='nav-link text-white text-start fs-5'><i className='fa fa-check me-2' style={{ color: '#E62E5E' }}></i>Home </Link>
                                    </li>
                                    <li className='py-2'>
                                        <Link to='/about-us' className='nav-link text-white text-start fs-5'><i className='fa fa-check me-2' style={{ color: '#E62E5E' }}></i>About Us </Link>
                                    </li>

                                    <li className='py-2'>
                                        <Link to='/services' className='nav-link text-white text-start fs-5'><i className='fa fa-check me-2' style={{ color: '#E62E5E' }}></i>Services </Link>
                                    </li>
                                    <li className='py-2'>
                                        <Link to='/teams' className='nav-link text-white text-start fs-5'><i className='fa fa-check me-2' style={{ color: '#E62E5E' }}></i>Teams </Link>
                                    </li>
                                    <li className='py-2'>
                                        <Link to='/projects' className='nav-link text-white text-start fs-5'><i className='fa fa-check me-2' style={{ color: '#E62E5E' }}></i>Projects </Link>
                                    </li>
                                    <li className='py-2'>
                                        <Link to='/contactus' className='nav-link text-white text-start fs-5'><i className='fa fa-check me-2' style={{ color: '#E62E5E' }}></i>Contact Us </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>


                        {/* <div className='col-md-3 '>
                            <h4 className='text-white mb-4 text-start ms-5'><span style={{ borderBottom: '3px solid #E62E5E', paddingBottom: '15px' }}>Our Services</span></h4>
                            <div>
                                <ul style={{ listStyle: 'none' }}>
                                    <li className='py-2'>
                                        <a href='services' className='nav-link text-white text-start fs-5'><i className='fa fa-check me-2' style={{ color: '#E62E5E' }}></i>Home Loan/Mortage Loan</a>
                                    </li>
                                    <li className='py-2'>
                                        <a href='services' className='nav-link text-white text-start fs-5'><i className='fa fa-check me-2' style={{ color: '#E62E5E' }}></i>Personal Loan</a>
                                    </li>
                                    <li className='py-2'>
                                        <a href='services' className='nav-link text-white text-start fs-5'><i className='fa fa-check me-2' style={{ color: '#E62E5E' }}></i>Business Loan </a>
                                    </li>
                                    <li className='py-2'>
                                        <a href='services' className='nav-link text-white text-start fs-5'><i className='fa fa-check me-2' style={{ color: '#E62E5E' }}></i>Education Loan </a>
                                    </li>
                                    <li className='py-2'>
                                        <a href='services' className='nav-link text-white text-start fs-5'><i className='fa fa-check me-2' style={{ color: '#E62E5E' }}></i>Car Loan</a>
                                    </li>
                                    <li className='py-2'>
                                        <a href='services' className='nav-link text-white text-start fs-5'><i className='fa fa-check me-2' style={{ color: '#E62E5E' }}></i>School infra Funding Loan</a>
                                    </li>
                                </ul>
                            </div>
                        </div> */}

                        <div className='col-md-4 '>
                            <h4 className='text-white mb-4 text-start ms-5 mb-5'><span style={{ borderBottom: '3px solid #E62E5E', paddingBottom: '15px' }}>Information</span></h4>
                            <div className='d-flex ms-2'>
                                <div>
                                    <i class="fa fa-map-marker  me-3" style={{ fontSize: '70px ', color: '#E62E5E' }} aria-hidden="true"></i>
                                </div>
                                <div className='text-start'>
                                    <h5 className='text-white'>Address</h5>
                                    <p className='text-white fs-5'>
                                    75, Vinayak Nagar, Varadharaja Nagar, Bengaluru, Karnataka 560014
                                    </p>
                                </div>

                            </div>

                            <div className='d-flex ms-2'>
                                <div>
                                    <i class="fa fa-envelope me-3" style={{ fontSize: '38px ', color: '#E62E5E' }} aria-hidden="true"></i>
                                </div>
                                <div className='text-start'>
                                    <h5 className='text-white text-start'>Email</h5>
                                    <p style={{ color: '#fff' }} className='fs-5'><a href='' className='text-white'>vasundhara.homes21@gmail.com</a></p>
                                </div>

                            </div>

                            <div className='d-flex ms-2'>
                                <div>
                                    <i class="fa fa-phone  me-3" style={{ fontSize: '41px ', color: '#E62E5E' }} aria-hidden="true"></i>
                                </div>
                                <div className='text-start'>
                                    <h5 className='text-white'>Mobile</h5>
                                    <p style={{ color: '#fff' }} className='fs-5'>  +91 9900118650</p>
                                </div>

                            </div>


                        </div>
                    </div>

                    <hr className='text-white' />
                    <div>
                        <p className='text-center text-white fs-5'>
                            Copyright 2023 © All Right Reserved  <a href='#' className='text-white'></a>
                        </p>
                    </div>
                </div>
            </div>


        </>
    )
}

export default Footer