import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Header from "./headers";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import Whatapp from "../cmp/whatapp";
import "../cmp/style.css";
const Aboutus = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top when the component mounts or pathname changes
  }, [pathname]);
  return (
    <>
      <Header />
      <div className="container-fluid home-brige d-flex flex-column align-items-center justify-content-center">
        <div className="">
          <h1 className="text-white display-3 fw-semibold">About Us</h1>
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item text-white ">
                <a href="#" className="text-white fs-5">
                  Home
                </a>
              </li>
              <li
                class="breadcrumb-item active text-white fs-5"
                aria-current="page"
              >
                About us
              </li>
            </ol>
          </nav>
        </div>
      </div>

      <div className="container py-5">
        <div className="row">
          <div>
            <h1 className="mb-5 fw-bold text-center " style={{ color: "red" }}>
              Welcome to Vasundara Constructions
            </h1>
          </div>
          <div className="col-md-5">
            <img src="ab.jpg" alt="pic" style={{ width: "100%" }} />
          </div>

          <div className="col-md-7">
            <h3 className="fw-bold">
              BUILDING AS PER YOUR <br />
              CONVENIENCE
            </h3>
            <p className="fs-5">
              Welcome to Vasundara Homes, where a decade of unwavering
              commitment to excellence meets the dynamic world of construction.
              Established in 2011, our journey has been marked by a passion for
              creating not just structures, but homes that stand as a testament
              to quality, innovation, and enduring design.
            </p>
            <p className="fs-5">
              With a solid foundation rooted in a Master's in Technology, my
              personal journey in the construction industry has spanned several
              years, providing me with a comprehensive understanding of the
              intricacies involved. This wealth of experience serves as the
              backbone of Vasundara Homes, infusing every project with a unique
              blend of expertise and vision
            </p>

            <p className="fs-5">
              Over the years, we have successfully completed 35 distinctive
              projects, each a testament to our dedication to craftsmanship and
              attention to detail. As we continue to evolve, we are currently
              engaged in 8 ongoing projects, where our commitment to excellence
              is unwavering.
            </p>

            <p className="fs-5">
              At Vasundara Homes, our portfolio speaks volumes. From crafting
              exquisite residential homes that embody the aspirations of our
              clients to tackling the challenges of industrial sheds with
              precision, we take pride in the diverse range of projects we've
              undertaken. Our expertise extends to managing BBMP contracts,
              ensuring that we navigate complex regulatory landscapes
              seamlessly.
            </p>
            <p className="fs-5">
              As we look ahead, Vasundara Homes is more than a construction
              company; it is a partner in your journey to create spaces that
              resonate with your dreams and aspirations. Join us in building not
              just structures but legacies that endure. Welcome to Vasundara
              Homes, where every project is a masterpiece in the making
            </p>
          </div>
        </div>
      </div>
      <div className="container py-5">
        <div className="row">
          <h1 style={{ color: "red" }} className="mb-4 text-center mb-5">
            Company Achievements
          </h1>
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-6 mb-5">
                <div className="d-flex">
                  <div className="project-svg me-4">
                    <img src="count.svg" className="svg" />
                  </div>
                  <div>
                    <h1>35</h1>
                    <h3>Projects Completed</h3>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-5">
                <div className="d-flex">
                  <div className="project-svg me-4">
                    <img src="team.svg" className="svg" />
                  </div>
                  <div>
                    <h1>30+</h1>
                    <h3>Expert Workers</h3>
                  </div>
                </div>
              </div>
              <hr className="mb-4" />
              <div className="col-md-6 mb-5">
                <div className="d-flex">
                  <div className="project-svg me-4">
                    <img src="client.svg" className="svg" />
                  </div>
                  <div>
                    <h1>40+</h1>
                    <h3>Happy Clients</h3>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-5">
                <div className="d-flex">
                  <div className="project-svg me-4">
                    <img src="count.svg" className="svg" />
                  </div>
                  <div>
                    <h1>4</h1>
                    <h3>Running Projects</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center">
            <button className="btn btn-info text-white py-3 fs-5">
              <Link to="/contactus" className="text-decoration-none text-white">
                Know More Contact us
              </Link>
            </button>
          </div>
        </div>
      </div>
      <Whatapp />
      <Footer />
    </>
  );
};

export default Aboutus;
