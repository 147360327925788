import React, { useRef, useState, useEffect } from "react";
import emailjs from "@emailjs/browser";
import Header from "./headers";
import {
  Nav,
  Navbar,
  Container,
  Dropdown,
  DropdownButton,
  Carousel,
  Accordion,
} from "react-bootstrap";
import Carouselslider from "./Carousal";
import Countdown from "./Count";
import Accordingcom from "./According";
import { Link } from "react-router-dom";
import "../cmp/style.css";
import Whatapp from "../cmp/whatapp";
import Phone from "../cmp/phone";
import { Component } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Footer from "./Footer";
const Home = () => {
  const form = useRef();
  const [message, setMessage] = useState(""); // State for success/error message

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_oskixvh",
        "template_8mdo3uf",
        form.current,
        "TAcfZoldRBMFi_WI4"
      )
      .then(
        (result) => {
          console.log(result.text);
          setMessage("Message sent successfully");
          resetForm();
        },
        (error) => {
          console.log(error.text);
          setMessage("Message sending failed");
        }
      );
  };
  const resetForm = () => {
    form.current.reset(); // Reset the form
    // Show success message for 5 seconds
    setTimeout(() => {
      setMessage("");
    }, 5000);
  };

  // Use useEffect to clear the message state after 5 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setMessage("");
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [message]);

  const responsiveOptions = {
    0: {
      items: 1, // Show 1 item on screens smaller than 600px
    },
    600: {
      items: 2, // Show 2 items on screens between 600px and 1000px
    },
    1000: {
      items: 3, // Show 3 items on screens larger than 1000px
    },
  };

  return (
    <>
      <Header />

      <div className="container-fluid px-0">
        <Carousel data-bs-theme="dark">
          <Carousel.Item interval={3000}>
            <div className="bg-box">
              <img
                className="d-block w-100 img-res bg-img"
                src="bg-1.jpg"
                alt="First slide"
              />

              <Carousel.Caption className="slider-1">
                {/* <h1 className='display-3 fw-bold' data-aos="fade-right" data-aos-duration="2000" style={{color:'#5918B0'}}>Get Loan With Easy Steps</h1>
                                <p className='fs-5 fw-bold res-hide' style={{color:'black'}} data-aos="fade-left" data-aos-duration="2000">you can get any kind of loans like personal loan, business loan , home loan etc. <br />with easy process and assistance</p>
                                 <button className='btn btn-info px-4 fs-5' data-aos="fade-up"><a  className='text-decoration-none text-white' href='services' target='_blank'>Read More</a></button> */}
              </Carousel.Caption>
            </div>
          </Carousel.Item>
          {/* <Carousel.Item interval={3000}>
                        <img
                            className="d-block w-100 img-res"
                            src="bg-2.jpg"
                            alt="Second slide"
                        />
                       <Carousel.Caption className='slider-1'>
                                <h1 className='display-3 fw-bold' style={{color:'#5918B0'}} data-aos="fade-right" data-aos-duration="2000" >Get Loan With Easy Steps</h1>
                                <p className='fs-5 fw-bold  res-hide' style={{color:'black'}} data-aos="fade-left" data-aos-duration="2000">you can get any kind of loans like personal loan, business loan , home loan etc. <br />with easy process and assistance</p>
                                 <button className='btn btn-info px-4 fs-5' data-aos="fade-up"><a  className='text-decoration-none text-white' href='#'>Read More</a></button>
                            </Carousel.Caption>
                            </Carousel.Item> */}
          {/* <Carousel.Item interval={2000}>
                            <img
                                className="d-block w-100"
                                src="../Assets/Banner-3.png"
                                alt="Third slide"
                            />
                            <Carousel.Caption>
                                <h5>Third slide label</h5>
                                <p>
                                    Praesent commodo cursus magna, vel scelerisque nisl consectetur.
                                </p>
                            </Carousel.Caption>
                        </Carousel.Item> */}
        </Carousel>
      </div>

      <div className="container fes-box bg-white py-4  shadow">
        <div className="row">
          <div className="col-md-6">
            <div className="d-flex first">
              <div>
                <img src="fea-1.svg" alr="" className="px-3 svg-image" />
              </div>
              <div className="auto-box">
                <h2>Building Contruction</h2>
                <p>
                  They ensure compliance with building codes, safety
                  regulations, and quality standards while adhering to project.
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="d-flex first">
              <div>
                <img src="fea-2.svg" alr="" className="px-3 svg-image" />
              </div>
              <div>
                <h2>Interior Designing</h2>
                <p>
                  Construction services also encompass renovating and existing
                  structures to update them change layout
                </p>
              </div>
            </div>
          </div>

          {/* <div className='col-md-4'>
                        <div className='d-flex first'>
                            <div>
                                <img src='fea-3.svg' alr='' className='px-3 svg-image' />
                            </div>
                            <div>
                                <h2>House
Renovation</h2>
                                <p>
                                This includes building homes, apartments, and housing units. It can involve single-family homes and more.
                                </p>
                            </div>
                        </div>
                    </div> */}
        </div>
      </div>

      <div className="container">
        <h2 className="text-center">Our Services</h2>
        <h3 className="text-center display-5 fw-bold">
          We Providing Best Services
        </h3>
      </div>

      <div className="bg-light py-5">
        <div className="container mb-5">
          <div className="row">
            <div className="col-md-6">
              <img src="about.jpg" style={{ width: "100%" }} />
            </div>
            <div className="col-md-6">
              <p>WHO WE ARE</p>
              <h2 className="display-5 fw-bold">
                A Modern Construction & Industrial Agency
              </h2>
              <p>
                Proactively pontificate client-centered relationships vis-a-vis
                process centric leadership skills. Credibly maintain focused
                internal or organic sources rather than vertical alignments.
              </p>
              <div className="d-flex mb-3">
                <div
                  style={{
                    width: "80px",
                    backgroundColor: "#F6E5E0",
                    height: "80px",
                    borderRadius: "5px",
                  }}
                  className=" d-flex justify-content-center p-3 me-4"
                >
                  <img src="mission.svg" />
                </div>
                <div>
                  <h4>Our Mission</h4>
                  <p>
                    Advanced maintain focused internal or organic sources rather
                    than vertical alignments.
                  </p>
                </div>
              </div>

              <div className="d-flex mb-4">
                <div
                  style={{
                    width: "88px",
                    backgroundColor: "#F6E5E0",
                    height: "80px",
                    borderRadius: "5px",
                  }}
                  className=" d-flex justify-content-center p-3 me-4"
                >
                  <img src="certi.svg" className="img-vision" />
                </div>
                <div>
                  <h4>Our Vision</h4>
                  <p>
                    Credibly maintain focused internal or organic sources rather
                    than vertical stnemngils.
                  </p>
                </div>
              </div>

              <div className="d-flex align-items-center about-more">
                <div className="me-5 about-box">
                  <button className="btn btn-info text-white px-4 py-3 fs-5 mb-5">
                    <Link
                      to="/about-us"
                      className="text-decoration-none text-white"
                    >
                      About More
                    </Link>
                  </button>
                </div>
                <div className="d-flex">
                  <Phone />

                  <div className="ms-4">
                    <h4>Have Any Question</h4>
                    <p className="fw-bold">+91 8147880677</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container py-5">
        <div className="row">
          <div className="col-md-6">
            {/* <h4>FREQUENTLY ASK QUESTIONS</h4> */}
            <h2 className="display-4 mb-4 fw-bold">
              Frequently Asked Questions
            </h2>
            <div>
              <Accordingcom />
            </div>
          </div>
          <div className="col-md-6">
            <img src="faq.png" style={{ width: "100%" }} />
          </div>
        </div>
      </div>

      <div className="contact-bg py-5">
        <div
          className="bg-white w-50 h-75 mob-res"
          style={{ position: "absolute", right: "20%" }}
        >
          <div className="row">
            <div className="col-md-4 ">
              <div
                className="px-3 py-4 quote-box"
                style={{
                  backgroundColor: "#E85B2A",
                  width: "100%",
                  height: "95.7%",
                }}
              >
                <h3 className="text-white">
                  You Get Free Consultation For Any Help
                </h3>
              </div>
            </div>
            <div className="col-md-8 py-4 ">
              <div className="py-3">
                <h3 className="text-center mb-4">Request A Quote</h3>
                <div>
                  {message && <div className="success-message">{message}</div>}
                  <form ref={form} onSubmit={sendEmail} autoComplete="off">
                    <div className="row px-3">
                      <div className="col-md-6">
                        <div class="form-floating mb-3">
                          <input
                            type="text"
                            class="form-control"
                            id="name"
                            name="user_name"
                            required
                            placeholder="name"
                          />
                          <label for="floatingInput">Your Name</label>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div class="form-floating mb-3">
                          <input
                            type="email"
                            class="form-control"
                            id="email"
                            name="user_email"
                            required
                            placeholder="name@example.com"
                          />
                          <label for="floatingInput">Email Id</label>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div class="form-floating mb-3">
                          <input
                            type="number"
                            class="form-control"
                            id="floatingInput"
                            placeholder="name"
                            name="user_mobile"
                            required
                          />
                          <label for="floatingInput">Mobile No.</label>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div class="form-floating mb-3">
                          <input
                            type="text"
                            class="form-control"
                            id="floatingInput"
                            name="user_city"
                            required
                            placeholder="name@example.com"
                          />
                          <label for="floatingInput">City</label>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div class="form-floating mb-3">
                          <textarea
                            class="form-control"
                            placeholder="Leave a comment here"
                            id="floatingTextarea2"
                            name="user_message"
                            style={{ height: "130px" }}
                          ></textarea>
                          <label for="floatingTextarea2">Comments</label>
                        </div>
                      </div>

                      <div className="col-md-12 text-center">
                        <button type="submit" class="btn btn-primary">
                          Send Message
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid bg-dark py-5">
        <div className="container py-3">
          <h2 className="text-white text-center display-5 fw-bold mb-5">
            What Our Clients Say?
          </h2>
          <OwlCarousel
            items={3}
            className="owl-theme"
            loop
            nav
            margin={20}
            autoplay
            autoplayTimeout={5000}
            responsive={responsiveOptions}
          >
            <div className=" mb-4">
              <div class="card border-0 shadow-lg">
                <div className="w-25 text-center rounded-circle mx-auto mt-3">
                  <img src="test-2.jpg" className="rounded-circle" />
                </div>
                <div class="card-body text-center">
                  <p class="card-text fs-5">
                    "I am thrilled with Vasundara Constructions who built my
                    home in Vidyaranyapura. Their professionalism, attention to
                    detail, and clear communication made the entire process
                    seamless. The team's expertise and commitment to quality met
                    my expectations. The project was completed on time, and the
                    final result is a dream home that surpasses my vision. I
                    highly recommend this contractor for their skillful work and
                    exceptional service."
                  </p>

                  <h5 class="card-title text-black">Mary Cruzleen</h5>
                </div>
              </div>
            </div>

            <div className=" mb-4">
              <div class="card border-0 shadow-lg">
                <div className="w-25 text-center rounded-circle mx-auto mt-3">
                  <img src="test-3.jpg" className="rounded-circle" />
                </div>
                <div class="card-body text-center">
                  <p class="card-text fs-5">
                    "I am extremely satisfied with the civil contractor who
                    managed the construction of my commercial complex, including
                    the installation of an elevator. Their commitment to
                    punctuality and high-quality work was evident throughout the
                    project. The incorporation of the elevator was seamlessly
                    integrated into the construction process, adding a valuable
                    asset to the complex. The contractor's professionalism,
                    efficiency, and attention to detail make them my top choice
                    for anyone seeking reliable and comprehensive commercial
                    construction services, including elevator installation."
                  </p>

                  <h5 class="card-title text-black">Romika jain</h5>
                </div>
              </div>
            </div>

            <div className=" mb-4">
              <div class="card border-0 shadow-lg">
                <div className="w-25 text-center rounded-circle mx-auto mt-3">
                  <img src="test-1.jpg" className="rounded-circle" />
                </div>
                <div class="card-body text-center">
                  <p class="card-text fs-5">
                    "I am thrilled with the civil contractor I hired to build my
                    home in Police Layout, Mysore. The entire construction
                    process, from start to finish, went smoothly and
                    efficiently. What impressed me most was the reasonable
                    quotation provided for the high-quality work delivered. The
                    contractor's professionalism, transparency, and dedication
                    to delivering a top-notch home were truly commendable. I
                    highly recommend them for anyone looking for a reliable and
                    cost-effective option in construction services."
                  </p>

                  <h5 class="card-title text-black">Rohit jaisawal</h5>
                </div>
              </div>
            </div>

            <div className=" mb-4">
              <div class="card border-0 shadow-lg">
                <div className="w-25 text-center rounded-circle mx-auto mt-3">
                  <img src="test-3.jpg" className="rounded-circle" />
                </div>
                <div class="card-body text-center">
                  <p class="card-text fs-5">
                    "I recently hired Vasundara Constructions to renovate my
                    10-year-old bathroom and terrace, and I was really happy
                    with the results. The team's expertise and attention to
                    detail transformed my home beyond expectations. The
                    communication throughout the renovation was excellent, and
                    they seamlessly addressed any concerns. The project was
                    completed within the agreed timeline, showcasing their
                    efficiency. I highly recommend this contractor for their
                    outstanding work and the positive experience they provided
                    during the renovation process."
                  </p>

                  <h5 class="card-title text-black">Romika jain</h5>
                </div>
              </div>
            </div>

            <div className=" mb-4">
              <div class="card border-0 shadow-lg">
                <div className="w-25 text-center rounded-circle mx-auto mt-3">
                  <img src="test-2.jpg" className="rounded-circle" />
                </div>
                <div class="card-body text-center">
                  <p class="card-text fs-5">
                    "I am incredibly satisfied with Vasundara Constructions who
                    took on the painting and waterproofing of my apartment in MS
                    Palya. The speed and efficiency with which they completed
                    the project were impressive. Not only did they finish ahead
                    of schedule, but the attention to detail and quality of work
                    left me thoroughly pleased. I appreciate the professionalism
                    and the quick, satisfying transformation of my living space.
                    Highly recommend this contractor for their prompt service
                    and exceptional results!"
                  </p>

                  <h5 class="card-title text-black">Mary Cruzleen</h5>
                </div>
              </div>
            </div>
          </OwlCarousel>
        </div>
      </div>
      <Whatapp />
      <Footer />
    </>
  );
};

export default Home;
